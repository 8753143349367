/* eslint-disable no-unused-vars */
import apiService from '@/utils/api-client'
import config from '@/utils/appConfig'
import store from '@/store'
import axios from 'axios'
import configApp from '@/utils/appConfig'

const axiosClient = axios.create()

axiosClient.interceptors.request.use(
  config => {
    config.baseURL =
      config.baseURL ||
      store.state.analytics.coinData.baseUrl ||
      configApp.VUE_APP_API_BASE_URL

    if (store.state.pdf.apiKey)
      config.headers['x-api-key'] = store.getters['pdf/getApiKey']

    return config
  },
  error => error,
)

export default {
  async getEthAddressInfo({ commit }, { address }) {
    try {
      const data = axiosClient.get(`explorer/getAddressInfo/${address}`)
      return data
    } catch (error) {
      console.error(error)
    }
  },
  async getAddressBalance({ commit }, { address, contract }) {
    try {
      const data = axiosClient.get(`explorer/getBalance/${address}`, {
        params: {
          contract: contract === 'all' ? undefined : contract,
        },
      })

      return data
    } catch (error) {
      console.error(error)
    }
  },
  async getReportAddress(
    { commit },
    {
      address,
      direction = config.VUE_APP_SCORE_REQUEST_DIRECTION,
      token,
      tokenTicker,
      totalFunds,
      alerts,
      isMonitoring,
      shallow = config.VUE_APP_SHALLOW,
      ...extraParams
    },
  ) {
    const data = axiosClient.get(`report/address/${address}`, {
      params: {
        ...extraParams,
        shallow,
        direction,
        isMonitoring,
        token: token === 'all' || token === undefined ? 'supported' : token,
        tokenTicker,
        // $project: { totalFunds, alerts },
      },
    })

    return data
  },
  async getTokens({ commit }, { supported = true, token }) {
    try {
      const data = axiosClient.get(`token`, {
        params: {
          token,
          supported: supported || undefined,
          $limit: supported ? 1000 : undefined,
        },
      })

      return data
    } catch (error) {
      console.error(error)
    }
  },
  async getAddressMetrics(
    { commit },
    { startRangeDate, endRangeDate, address, contract = '', type = 'main' },
  ) {
    try {
      const data = axiosClient.get(
        `explorer/addressMetrics/${
          store.state.analytics.coinData.key === 'trx'
            ? address
            : address.toLowerCase()
        }`,
        {
          params: {
            contract: contract === 'all' ? '' : contract,
            type,
            startRangeDate: startRangeDate || undefined,
            endRangeDate: endRangeDate || undefined,
          },
        },
      )

      return data
    } catch (error) {
      console.error(error)
    }
  },
  async getAddressTokens({ commit }, { address, supported = true }) {
    try {
      const data = axiosClient.get(`explorer/getAddressTokens/${address}`, {
        params: {
          supported: supported || undefined,
          $limit: supported ? 1000 : undefined,
        },
      })

      return data
    } catch (error) {
      console.error(error)
    }
  },
  async getTransactionTokens({ commit }, { tx, supported = true }) {
    try {
      const data = axiosClient.get(`explorer/getTransactionTokens/${tx}`, {
        params: { supported: supported || undefined },
      })

      return data
    } catch (error) {
      console.error(error)
    }
  },
  async getTxEthData(
    { commit },
    {
      tx,
      currentDate = undefined,
      contract = undefined,
      startRangeDate = undefined,
      endRangeDate = undefined,
      sort = undefined,
      limit = undefined,
      page = undefined,
      only = undefined,
      address,
    },
  ) {
    try {
      const data = await axiosClient.get(`explorer/txBrowserIO`, {
        params: {
          tx_hash: tx,
          startRangeDate,
          endRangeDate,
          contract,
          currentDate,
          sort,
          limit,
          page,
          only,
          address,
        },
      })

      return data
    } catch (error) {
      console.error(error)
    }
  },
  async getReportTx(
    { commit },
    {
      tx_hash,
      direction = config.VUE_APP_SCORE_REQUEST_DIRECTION,
      token,
      tokenTicker,
      totalFunds,
      ...extraParams
    },
  ) {
    try {
      const data = await axiosClient.get(`report/tx_hash/${tx_hash}`, {
        params: {
          ...extraParams,
          direction,
          token: token || '',
          tokenTicker,
          // $project: { totalFunds },
        },
      })
      return data
      // eslint-disable-next-line no-empty
    } finally {
    }
  },
  async getTransactionInfo(
    { commit },
    {
      txHash,
      isAddress = false,
      only = undefined,
      sortByAddress = undefined,
      inputsPerPage = only === 'outputs' ? 1000 : 20,
      outputsPerPage = only === 'inputs' ? 1000 : 20,
      inputsSkip = 0,
      outputsSkip = 0,
    },
  ) {
    try {
      const data = axiosClient.get(
        `explorer/txBrowserIO?${isAddress ? 'address' : 'tx_hash'}=${txHash}`,
        {
          params: {
            only,
            // isAddress,
            inputsPerPage,
            outputsPerPage,
            inputsSkip,
            outputsSkip,
            sortByAddress,
          },
        },
      )

      return data
    } catch (error) {
      console.error(error)
    }
  },
  async getAddressData({ commit }, { address, timestamp = undefined }) {
    try {
      const data = await axiosClient.get(`explorer/getAddressInfo/${address}`, {
        params: { timestamp },
      })

      return data
    } catch (error) {
      console.error(error)
    }
  },
}
